import fullpage from "fullpage.js";
import slidesConfig from "../slidesConfig.json";
import { createIcons, ArrowRightCircle, CirclePlus, MoveDown } from "lucide";

const container = document.getElementById("fullpage");
const loader = document.getElementById("loader");
const menu__toggle = document.getElementById("menu__toggle");
const menu__items = document.getElementById("menu__items");

function createAllSlides() {
  if (!container) return;

  try {
    const config = slidesConfig;

    for (const [slideName, slideData] of Object.entries(config)) {
      let section = document.getElementById(`sr--${slideData.row}`);

      if (section === null) {
        section = document.createElement("div");
        section.className = "section";
        section.id = `sr--${slideData.row}`;
      }

      const slide = createSlide(
        slideName,
        slideData.text || undefined,
        slideData.coming_soon || false,
        slideData.action || undefined
      );

      section.appendChild(slide);
      container.appendChild(section);
    }
  } catch (error) {
    console.error("Error loading slides configuration:", error);
  }
}

function createSlide(title, headingText, comingSoon, action) {
  if (!title) return;

  if (headingText === undefined) headingText = title;

  // Create slide
  const slide = document.createElement("div");
  slide.className = "slide";
  slide.id = `s--${title.toLowerCase().replace(" ", "-")}`;
  // slide.setAttribute("data-anchor", title.toLowerCase().replace(" ", "-"));

  // Create slide container
  const slideContainer = document.createElement("div");
  slideContainer.className = "slide__container";

  // Create card
  const card = document.createElement("div");
  card.className = "slide__card";

  // Create heading
  // (if title is "Home", use h2, otherwise use h1)
  const htype = title === "Home" ? "h2" : "h1";
  const heading = document.createElement(htype);
  const span = document.createElement("span");
  span.textContent = headingText;
  heading.appendChild(span);

  // Append elements
  card.appendChild(heading);

  if (comingSoon) {
    // Create coming soon badge if necessary
    const badge = createCSBadge();
    card.appendChild(badge);
  } else if (action) {
    // Create action button if necessary
    const button = createAction();

    card.appendChild(button);
  }

  slideContainer.appendChild(card);
  slide.appendChild(slideContainer);

  return slide;

  function createCSBadge() {
    const badge = document.createElement("div");
    badge.className = "slide__action action--cs";

    const badgeText = document.createElement("h3");
    badgeText.textContent = "Coming Soon";

    badge.appendChild(badgeText);
    return badge;
  }

  function createAction() {
    const button = document.createElement("div");
    button.className = `slide__action action--${action.type}`;

    const iconType =
      action.type === "go"
        ? "arrow-right-circle"
        : action.type === "down"
        ? "move-down"
        : "circle-plus";
    button.innerHTML = `<i data-lucide=${iconType}></i>`;

    const goFunc = () => {
      window.open(action.link, "_blank");
    };

    const downFunc = () => {
      DMcLfullPage.moveSectionDown();
    };

    // const moreFunc = () => {
    //   "..."
    // }

    button.addEventListener("click", () => {
      if (action.type === "go") {
        goFunc();
      } else if (action.type === "down") {
        downFunc();
      } else {
        console.error("Invalid action type:", action.type);
      }
    });
    return button;
  }
}

// loader stuff
function fadeOutLoader() {
  loader.classList.add("fade-out");
}

setTimeout(fadeOutLoader, 1738);

//  add reload listeners
window.addEventListener("load", () => {
  // ensure url does not end with "/#home"
  if (window.location.href.endsWith("/#home")) {
    window.location.href = window.location.href.slice(0, -5);
  }

  // reset gif
  const loaderImg = document.querySelector("#loader img");
  const source = loaderImg.src;
  loaderImg.src = "";
  loaderImg.src = source;
});

// Menu toggle functionality
function toggleMenu() {
  menu__items.classList.toggle("open");
}

menu__toggle.addEventListener("click", toggleMenu);

//set up page
createAllSlides();
createIcons({
  icons: {
    ArrowRightCircle,
    CirclePlus,
    MoveDown,
  },
});

var DMcLfullPage = new fullpage("#fullpage", {
  licenseKey: "7L147-T9QB6-M95KK-5J167-TOJTQ",
  anchors: ["home", "career", "projects", "contact"],
  credits: {
    enabled: false,
  },
});
