{
  "Home": {
    "row": 1,
    "img": "nun_personal.gif",
    "text": "Use your keyboard, mouse, or touchscreen to navigate the experience.",
    "action": {
      "type": "down"
    }
  },
  "About": { "row": 2, "img": "about.png", "coming_soon": true },
  "Discography": {
    "row": 2,
    "img": "discog.png",
    "action": {
      "type": "go",
      "link": "https://orcd.co/the-changing-of-the-trees"
    }
  },
  "Music Videos": {
    "row": 2,
    "img": "mvids.png",
    "action": {
      "type": "go",
      "link": "https://www.youtube.com/playlist?list=PLG0NUanfvzYB6aRndEtpzvuDPVU1L97wP"
    }
  },
  "Performances": {
    "row": 2,
    "img": "performances.png",
    "action": {
      "type": "go",
      "link": "https://www.youtube.com/playlist?list=PLG0NUanfvzYAZ647S_KtVC67kIC1qivKK"
    }
  },
  "COURTVISION": {
    "row": 3,
    "img": "cv.png",
    "action": { "type": "go", "link": "https://poweredbycourtvision.com/" }
  },
  "Elsewhere Transmissions": {
    "row": 3,
    "img": "elsewhere.png",
    "action": { "type": "go", "link": "https://www.twitch.tv/dommclennon" }
  },
  "Contact": {
    "row": 4,
    "img": "the_fisheye.png",
    "action": { "type": "go", "link": "mailto:jon@poweredbycourtvision.com" }
  }
}
